<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import EmailAutocomplete from '@/components/EmailAutocomplete.vue'
import EmailChipsInput from '@/components/EmailChipsInput.vue'

defineProps<{
  loading: boolean
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['document/DialogAddSigner']['contextData']): void
}>()

const { t } = useI18n()

const { isPrePricing2023 } = storeToRefs(useBusinessStore())

const emails = ref<string[]>([])

const isValidInput = computed(() => Boolean(emails.value.length))

const add = () => {
  emit('action', { emails: emails.value })
}
</script>

<template>
  <base-dialog
    data-cy="add_signer_dialog"
    :title="t('add_signer.dialog.title')"
    :disabled="!isValidInput || loading"
    :loading="loading"
    :action-text="t('add_signer.dialog.add')"
    :cancel-text="t('global.no_thanks')"
    @action="add"
    @close="emit('close')"
  >
    <div class="mb-4">
      {{ t('add_signer.dialog.email.title') }}
    </div>
    <email-chips-input
      v-if="isPrePricing2023"
      placeholder="name@company.com, name@company.com"
      @setEmails="newEmails => (emails = newEmails)"
    />
    <email-autocomplete
      v-else
      v-model="emails"
      multiple
      placeholder="name@company.com, name@company.com"
      :label="$t('business.members.email_addresses')"
    />
  </base-dialog>
</template>
